@charset "UTF-8";

@import "definitions";

.slick-slide {
  min-height: initial;
  /* スライド画像下に謎の空白ができる対策 */
  > div{
    font-size: 0;
    line-height: 1;
  }
}

#welfare_slider .slider{
  min-height: 230px;
  margin-bottom: 40px;
  overflow: hidden;
  background: url(../images/loading.gif) no-repeat center center;
  .slick-list{
    overflow: visible;
  }
  .slide_image{
    width: 100%;
    position: relative;
    img{
      width: 100%;
    }
    .caption{
      position: absolute;
      bottom: 0;
      left: 0;
      background-color: #fff;
      padding: 0.6em 1em;
      margin-bottom: 0;
      font-size: 1.0625rem;
      line-height: 1.7;
      font-weight: bold;
      white-space: nowrap;
      p{
        margin-bottom: 0;
      }
    }
  }
}

#welfare_slider .thumbs{
  .slick-track{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: 12px;
    &:before{
      display: none;
    }
    .slick-slide{
      width: 80px !important;
      &.slick-current{
        .thumb_image{
          opacity: 1;
        }
      }
    }
  }
  .thumb_image{
    width: 100%;
    opacity: 0.5;
    transition: .3s;
    img{
      width: 100%;
    }
  }
}

@media (hover: hover) {
  #welfare_slider .thumbs{
    .thumb_image:hover {
      opacity: 1;
      cursor: pointer;
    }
  }
}

@media screen and (max-width: 768px) {

  #welfare_slider .slider{
    .slide_image{
      .caption{
        font-size: 1rem;
        padding: 0.5em 1.3em;
      }
    }
  }
  
  #welfare_slider .thumbs{
    .slick-track{
      display: flex;
      justify-content: flex-start;
      gap: 8px;
      .slick-slide{
        width: calc((100% - 8px * 7) / 8) !important;
      }
    }
  }

}

@media screen and (max-width: 540px) {

  #welfare_slider .slider{
    .slide_image{
      .caption{
        font-size: 0.86rem;
      }
    }
  }
  
  #welfare_slider .thumbs{
    .slick-track{
      gap: 4px;
      .slick-slide{
        width: calc((100% - 4px * 7) / 8) !important;
      }
    }
  }
}